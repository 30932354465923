import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { validateTypeUser } from 'helpers/validateTypeUser'
import { toast } from 'react-toastify'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Button } from '@material-ui/core'
import { formatToCPF } from 'brazilian-values'
import MUIDataTable from 'mui-datatables'
import { textLabels } from 'helpers/textLabels'
import styles from './styles'
import Answers from './Answers'
import Status from './Status'

class Dashboard extends React.Component {
  state = {
    data: [],
    loading: false,
    page: 0,
    count: 1,
    columns: [],
  }

  async componentDidMount() {
    validateTypeUser(this.props, this.props.auth.groups)
    this.loadData(this.state.page + 1)
    this.renderColumns()
  }

  loadData = async page => {
    try {
      await this.setState({ loading: true })

      const getData = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_QUIZ_API}/reports/dashboard?cognito_sub=${this.props.auth.sub}&start_date=23-08-2019&final_date=23-08-2029&page=${page}`,
        headers: {
          'x-api-key': this.props.auth.api_key,
        },
      })
      if (getData) {
        await this.setState({
          data: getData.data.quizzes,
          count: getData.data.total,
          page: parseInt(getData.data.page) - 1,
        })
      }
    } catch (error) {
      if (error.response) {
        toast.warn('Houve um problema ao carregar os dados.')
      }
    } finally {
      await this.setState({ loading: false })
    }
  }

  renderColumns = async () => {
    const columns = [
      {
        name: 'date_response',
        label: 'Data/Hora',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'cpf',
        label: 'CPF',
        options: {
          filter: true,
          sort: false,
          customBodyRender: value => {
            try {
              return value ? formatToCPF(value) : null
            } catch {
              return false
            }
          },
        },
      },
      {
        name: 'type',
        label: 'Tipo de Quiz',
        options: {
          filter: true,
          sort: false,
          customBodyRender: value => {
            try {
              return value === 'true_false' ? 'V/F' : 'Multipla Escolha'
            } catch {
              return false
            }
          },
        },
      },
      {
        name: 'number_questions',
        label: 'Qtde de Perguntas Corretas',
        options: {
          filter: true,
          sort: false,
          customBodyRender: value => {
            try {
              return value
            } catch {
              return false
            }
          },
        },
      },
      {
        name: 'questions',
        label: 'Questão 1',
        options: {
          filter: true,
          sort: false,
          customBodyRender: value => {
            try {
              const data = value[0]
              return <Answers data={data} />
            } catch {
              return false
            }
          },
        },
      },
      {
        name: 'questions',
        label: 'Questão 2',
        options: {
          filter: true,
          sort: false,
          customBodyRender: value => {
            try {
              const data = value[1]
              return <Answers data={data} />
            } catch {
              return false
            }
          },
        },
      },
      {
        name: 'questions',
        label: 'Questão 3',
        options: {
          filter: true,
          sort: false,
          customBodyRender: value => {
            try {
              const data = value[2]
              return <Answers data={data} />
            } catch {
              return false
            }
          },
        },
      },
      {
        name: 'questions',
        label: 'Questão 4',
        options: {
          filter: true,
          sort: false,
          customBodyRender: value => {
            try {
              const data = value[3]
              return <Answers data={data} />
            } catch {
              return false
            }
          },
        },
      },
      {
        name: 'questions',
        label: 'Questão 5',
        options: {
          filter: true,
          sort: false,
          customBodyRender: value => {
            try {
              const data = value[4]
              return <Answers data={data} />
            } catch {
              return false
            }
          },
        },
      },
      {
        name: 'questions',
        label: 'Questão 6',
        options: {
          filter: true,
          sort: false,
          customBodyRender: value => {
            try {
              const data = value[5]
              return <Answers data={data} />
            } catch {
              return false
            }
          },
        },
      },
      {
        name: 'status',
        label: 'Resultado',
        options: {
          filter: true,
          sort: false,
          customBodyRender: value => {
            try {
              return <Status value={value} />
            } catch {
              return false
            }
          },
        },
      },
      {
        name: 'cpf_blocked',
        label: 'Ação',
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta) => {
            const page = tableMeta.tableState.page + 1
            const { rowIndex } = tableMeta
            try {
              const cpf = JSON.stringify(tableMeta.rowData[1])
              return JSON.stringify(value) !== 'false' ? (
                <Button color="primary" variant="contained" onClick={() => this.unlockCpf(cpf, page, rowIndex)}>
                  Desbloquear
                </Button>
              ) : (
                <Button
                  color="primary"
                  disabled
                  variant="contained"
                  onClick={() => this.unlockCpf(cpf, page, rowIndex)}
                >
                  Desbloquear
                </Button>
              )
            } catch {
              return false
            }
          },
        },
      },
    ]
    await this.setState({ columns })
  }

  changePage = async page => {
    this.setState({
      loading: true,
    })
    await this.loadData(page)
    this.setState({ page })
  }

  unlockCpf = async (cpf, page, rowIndex) => {
    try {
      const data = {
        cpf: `${cpf.replace(/"/g, '')}`,
        cognito_sub: this.props.auth.sub,
      }
      const unlock = await axios({
        method: 'PUT',
        url: `${process.env.REACT_APP_QUIZ_API}/releasecpf`,
        headers: {
          'x-api-key': this.props.auth.api_key,
        },
        data,
      })
      if (unlock.status === 200) {
        // tableData[rowIndex].cpf_blocked = false
        const tableData = this.state.data.map(item => {
          if (item.cpf === cpf.replace(/"/g, '')) {
            return {
              ...item,
              cpf_blocked: false,
            }
          }
          return item
        })

        await this.setState({
          data: tableData,
        })
        this.renderColumns()
      }
    } catch (error) {
      if (error.response) {
        toast.warn('Houve um problema ao carregar os dados.')
      }
    }
  }

  render() {
    const { classes } = this.props
    const { data, loading, count, page, columns } = this.state
    const options = {
      filterType: 'checkbox',
      filter: false,
      search: false,
      print: false,
      download: false,
      viewColumns: false,
      selectableRows: 'none',
      textLabels,
      elevation: 0,
      pagination: true,
      serverSide: true,
      responsive: 'scroll',
      count,
      page,
      rowsPerPage: 25,
      rowsPerPageOptions: [25],
      onTableChange: (action, tableState) => {
        switch (action) {
          case 'changePage':
            this.changePage(tableState.page + 1)
            break
          default:
            break
        }
      },
    }
    return (
      <div className={classes.root}>
        {loading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress className={classes.progress} />
          </div>
        ) : (
          <MUIDataTable data={data} columns={columns} options={options} />
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
})
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Dashboard))
